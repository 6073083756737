import React, { useEffect } from 'react'
import crypto from 'crypto';
import { useSearchParams } from "react-router-dom";
import { AppHeader } from '@shared/components/appHeader/AppHeader'
import { translate } from '@utils/translationUtils'
import {
    ClinicName,
    AccordionItem,
    ClinicAddress,
    AccordionItemInfo,
    AccordionItemInfoWrp,
    StyledExpansionPanel,
    StyledAccordionDetails,
    StyledAccordionSummary,
    ArrowWrapper,
    TreatmentSubTitle,
    TreatmentTitle,
    TreatmentLink
} from './styledComponents'
import { ImageSources } from '@shared/constants/imageSources'
import { ArrowRight } from '@shared/svg/index'
import { ClinicSearchField } from './components/ClinicSearchField'
import { withConnectedStore } from '@hocs/withConnectedStore'
import { AppLayout } from '@shared/components/appLayout/AppLayout'
import { withExpandedPanelsFunctionality } from '@hocs/withExpandedPanelsFunctionality'
import { saveClinic } from '@store/reducers/clinics'
import { Text } from '@containers/bookingResults/styledComponents'
import { useLocation } from "react-router-dom"
import clinics from '@store/reducers/clinics'
import { diggerPageView, diggerSaveData } from '@store/reducers/digger'
import { v4 as uuid } from 'uuid';


const ClinicSearch = ({
    store,
    dispatch,
    activeStep,
    increaseStep,
    handleChange,
    renderExpandIcon,
    setExpandedPanels,

}) => {
    const { regions, loading } = store.clinics
    const regionSort = [20, 13, 12, 11, 21];
    const clin = []




    const search = useLocation().search;
    regions.sort((a, b) => regionSort.indexOf(a.id) - regionSort.indexOf(b.id));

    const onClinicClick = clinic => () => {
        const testEnv = new URLSearchParams(search).get('testenv');
        if (testEnv === "true" || clinic.type === "Integrated") {
            dispatch(saveClinic(clinic))
            increaseStep()
        }
        else {
            const bookingUrl = "https://odontia.no/timebestilling-" + clinic.id;
            window.location.href = bookingUrl;
        }
    }

    const goToClinic = clinic => {
        const testEnv = new URLSearchParams(search).get('testenv');

        if (testEnv === "true" || clinic.type === "Integrated") {
            dispatch(saveClinic(clinic))
            increaseStep()
        }
        else {
            //window.location.href = 'https://odontia.no/timebestilling';
        }

    }

    const onHeaderClick = clinic => () => {

        window.location.href = 'https://odontia.no/tannbehandling-ungdom/';


    }

    useEffect(() => {
        const regionsIds = regions.map(({ id }) => id)
    }, [regions.length])
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        trackingCode = queryParams.get("od")
        var trackingCode = queryParams.get("trackingCode")
        var originalReferrer = queryParams.get("originalReferrer")
        var referer = queryParams.get("referer")
        var gclid = queryParams.get("gclid")
        var gclidType = queryParams.get("gclidType")
        var fbclid = queryParams.get("fbclid")
        var fbclidType = queryParams.get("fbclidType")

        if (trackingCode == null) {
            trackingCode = queryParams.get("od")
        }
        var session_id = queryParams.get("sessionId")
        if (session_id == null) {
            session_id = crypto.randomBytes(16).toString('base64');
        }



        const digger = {}  
        const googleClickId = {}
        const facebookClickId = {}
       
        if(gclid) {
            digger.googleClickId = googleClickId;
            digger.googleClickId.clickId = gclid;
            digger.googleClickId.type = gclidType;
        }
        if(fbclid) {
            digger.facebookClickId = facebookClickId;
            digger.facebookClickId.clickId = fbclid;
            digger.facebookClickId.type = "fbclid";
        }
        digger.sessionId = session_id;
        digger.referrer = referer,
            digger.path = "clinicSearch",
            digger.adCode = trackingCode,
            digger.browser = window.navigator.userAgent
        digger.originalReferrer = originalReferrer

        dispatch(diggerSaveData(digger));






    }, [])
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const clinicId = parseInt(queryParams.get("clinicId"), 10)

        if(clinicId) {
            regions.map(region => {
                region.clinics.find(clinic => {
                    if (clinic.id === clinicId) {
                        goToClinic(clinic);
                    }
                });
            })
        }
    }, [loading])



    return (
        <AppLayout loading={loading} activeStep={activeStep}>
            <AppHeader
                activeStep={activeStep}
                headerText={translate('chooseClinic')}

            />


            {(
                <TreatmentSubTitle>
                    <TreatmentTitle>{translate('ageComplient')}</TreatmentTitle>
                    {translate('omsorgsDepartementetInfo')}
                    <br />
                    <TreatmentLink onClick={onHeaderClick()}> {translate('readMoreHere')}</TreatmentLink>

                </TreatmentSubTitle>
            )}

            <ClinicSearchField />

            {!regions.length && <Text>{translate('noClinicsFound')}</Text>}



            {regions.map(({ id, name, clinics }) => (
                <StyledExpansionPanel
                    key={id}
                    onChange={handleChange(id)}
                    defaultExpanded={false}
                >
                    <StyledAccordionSummary expandIcon={renderExpandIcon(id)}>
                        {name}
                    </StyledAccordionSummary>

                    <StyledAccordionDetails>
                        <div>
                            {

                                clinics.sort((a, b) => a.name.localeCompare(b.name)).map(clinic => {
                                    const { id, zip, city, name, address1 } = clinic

                                    return (
                                        <AccordionItem
                                            key={id}
                                            onClick={onClinicClick(clinic)}
                                        >
                                            <AccordionItemInfoWrp>
                                                <img
                                                    src={ImageSources.clinicIcon}
                                                    alt="clinic ico"
                                                />
                                                <AccordionItemInfo>
                                                    <ClinicName>{name}</ClinicName>
                                                    <ClinicAddress>
                                                        {`${address1}, ${zip} ${city}`}
                                                    </ClinicAddress>
                                                </AccordionItemInfo>
                                            </AccordionItemInfoWrp>

                                            <ArrowWrapper>
                                                <ArrowRight />
                                            </ArrowWrapper>
                                        </AccordionItem>
                                    )
                                })}
                        </div>
                    </StyledAccordionDetails>
                </StyledExpansionPanel>
            ))}
        </AppLayout>
    )
}

export default withConnectedStore(withExpandedPanelsFunctionality(ClinicSearch))
